import moment from 'moment';
import WebHelpers from '~/app/webHelpers.js';
import {
  HIERARCHY_TYPE,
  BUSINESS_VIEW_ROLES,
  ROLE_TYPE,
  CORP_CONVERSION_PREFIX,
  TEN_FOURTY_CONVERSION_PREFIX,
  OCCURANCE_CHART,
} from '~/app/constants.js';
import logoCrosslink from '~/images/logoCrosslink.png';
import { removeHypen } from '~/app/Pages/TaxReturns/taxReturnsHelper.js';

/**
 * @module general
 * @category Utility
 */

/**
 * Takes an object and the value to get the object
 * key assigned to the value.
 *
 * @param {*} object
 * @param {*} value
 */
export const getObjectKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

/**
 * Checks if object properties are empty or null.
 */
export const isPartiallyEmpty = obj => {
  return Object.keys(obj).some(function (i) {
    return obj[i] === '' || obj[i] === null; // or just "return o[x];" for falsy values
  });
};

/**
 *
 * @param {number} n number to pad
 * @param {number} width total width of resulting number
 * @param {string} z Optional pad other than zero
 */
export const pad = (n, width, z) => {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

/**
 * Takes a string date format and returns unix timestamp.
 *
 * Example:
 * const strDateUnixFormat = dateStrToUnixTimestamp("2019-10-24");
 *
 * @param {*} strDate
 */
export const dateStrToUnixTimestamp = strDate => {
  return moment(strDate).valueOf();
};

/**
 * Returns a formated phone number.
 * @param {string} strNumber
 * @returns {string} (xxx) xxx-xxxx
 */
export const formatPhoneNumber = strNumber => {
  // remove all unnecessary characters
  const phoneNumber = strNumber.replace(/[^\d]/g, '');

  // check if phone number length equals to 10
  return phoneNumber.length === 10
    ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    : null;
};

/**
 * Returns a formated user name
 * @param {string} strNumber
 * @returns {string}
 */
export const formatUsername = strNumber => {
  // remove all invalid characters and lowercase
  return strNumber?.toLowerCase()?.replace(/[^a-z0-9-_]+/gi, '') || '';
};

/**
 * Checks whether an email is valid.
 * @param {string} strEmail
 * @returns {boolean} true / false
 */
export const validateEmail = strEmail => {
  const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return validEmail.test(strEmail);
};

/**
 * Checks whether a phone number is valid.
 * @param {string} strPhoneNumber
 * @returns {boolean} true / false
 */
export const validatePhoneNumber = strPhoneNumber => {
  const validPhoneNumber = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return validPhoneNumber.test(strPhoneNumber);
};

/**
 * Checks office profile for license on selected tax year or
 * if office was created before license management feature date.
 *
 * return boolean
 */
export const checkOfficeLicense = officeProfile => {
  const payload = WebHelpers.getJWTPayload();
  let hasLicense = false;
  const licenseSeasons = [];
  let oldestLicenseSeason = 0;

  // We check if the office has a license for the current year before
  // allowing the user to create a return.
  if (officeProfile && (officeProfile.has1040License || officeProfile.hasSubsequentLicense)) {
    hasLicense = true;
  }
  // Office has no license info
  else if (officeProfile && !officeProfile.has1040License && !officeProfile.hasSubsequentLicense) {
    hasLicense = false;
  }
  // This is for cases where officeProfile does not exist.
  // We set to true to return a react <Fragment/>
  else {
    hasLicense = true;
  }

  // **** Allow 2 previous season check ****
  //
  // We check for the oldest license season and compare it with
  // the selected season to see if it is within 2 previous season.
  // If it is, then bypass license check by setting "hasLicense" to true.
  if (officeProfile.licenseInfo) {
    // Determine oldest license office has.
    for (const license of officeProfile.licenseInfo) {
      licenseSeasons.push(license.season);
      if (!oldestLicenseSeason || license.season < oldestLicenseSeason) {
        oldestLicenseSeason = license.season;
      }
    }

    const initialSeason = 2019;
    // Provide two previous season from the oldest season.
    if (oldestLicenseSeason - 1 > initialSeason) {
      licenseSeasons.push(oldestLicenseSeason - 1);
    }
    if (oldestLicenseSeason - 2 >= initialSeason) {
      licenseSeasons.push(oldestLicenseSeason - 2);
    }

    // If tax year is within the last 2 season of the oldest license,
    // customer has access.
    const seasonRange = oldestLicenseSeason - payload.season;
    if (seasonRange >= 0 && seasonRange <= 2) {
      hasLicense = true;
    }
  }

  // For demo account, we need to ignore license check as well.
  if (payload.account_code === 'CLODMO') {
    hasLicense = true;
  }

  return { hasLicense, oldestLicenseSeason, licenseSeasons };
};

/**
 * Return the current day name.
 */
export const getDayOfWeek = () => {
  return moment().format('dddd');
};

/**
 * Convert the passed in startDate and endDate to
 * unix timestamp. Get the current date in unix timestamp.
 * Then check if current date is between the startDate
 * and endDate to display the header message.
 */
export const checkStartEndDate = (startDate, endDate) => {
  const currentTimestamp = moment().valueOf();
  startDate = dateStrToUnixTimestamp(startDate);
  endDate = dateStrToUnixTimestamp(endDate);
  // Both startDate and endDate exist so we check if current date
  // is between startDate and endDate.
  if (startDate && endDate) {
    if (currentTimestamp > startDate && currentTimestamp < endDate) {
      return true;
    }
  }
  // Only startDate was provided so we check if current date is on or
  // after startDate.
  else if (startDate && !endDate) {
    if (currentTimestamp > startDate) {
      return true;
    }
  }
  // Only endDate is provided, so we show the message immediately
  // and will hide the message on the end date timestamp.
  else if (!startDate && endDate) {
    if (currentTimestamp <= endDate) {
      return true;
    }
  }

  return false;
};

/**
 * This function determines whether or not a tech, admin, or super user
 * has logged in or impersonated a customer.
 *
 * TECH_SUPPORT_SBA represents one of our customers who can log in as tech support - they should only have access to accounts under their parent account CTPA21
 *
 * @param {Object} payload
 */
export const isTechOrAdminLogin = payload => {
  let isTech = false;
  if (
    payload.hierarchy_type_id === HIERARCHY_TYPE.ADMIN ||
    payload.hierarchy_type_id === HIERARCHY_TYPE.TECH_SUPPORT ||
    payload.hierarchy_type_id === HIERARCHY_TYPE.TECH_SUPPORT_SBA ||
    (payload.impersonating && !payload.is_super_user) ||
    payload.is_tech_support_and_impersonating
  ) {
    isTech = true;
  }

  return isTech;
};

/**
 * Display a message to the user their top parent account has not completed all agreements
 * and they will not be able to transmit.
 * @param {string} agreementStatus
 * @param {string} season
 * @returns boolean
 * @function
 */
export const areAgreementsSigned = (agreementStatus = 'N', season = 0) => {
  // If account agreements HOLD status is not an N, display the agreements message.
  return (season > 2021 || false) && agreementStatus === 'Y';
};

/**
 * This function determines whether or not current view role is admin or tech.
 *
 * @param {Object} currentView the current drilldown view
 */
export const isTechOrAdminLoginFromRole = currentView => {
  let isTech = false;
  if (
    currentView?.role === HIERARCHY_TYPE.ADMIN ||
    currentView?.role === HIERARCHY_TYPE.TECH_SUPPORT ||
    currentView?.role === HIERARCHY_TYPE.TECH_SUPPORT_SBA
  ) {
    isTech = true;
  }
  return isTech;
};

/**
 * Checks if hierarchy type id is EFIN level login types.
 *
 * @function
 * @param {Object} payload
 * @returns boolean
 * @function
 */
export const isEfinTypeLogins = payload => {
  const officeTypeLogins = [HIERARCHY_TYPE.EFIN, HIERARCHY_TYPE.PREPARER, HIERARCHY_TYPE.CLERK];

  return officeTypeLogins.includes(payload.hierarchy_type_id);
};

/**
 * Takes a date string and return a date string
 * in the format of YYYY-MM-DD
 *
 * @function
 * @returns YYYY-MM-DD
 */
export const getDateString = date => {
  return moment(date).format('YYYY-MM-DD');
};

/**
 * Displays a logo.
 *
 * If domain is "myonlinetaxoffice" or "cobrand", default logo to an empty string
 * to not display a logo to begin with. Once the user has gone
 * pass the login, we'll store the co-branding logo in localstorage.
 * When the user returns on the same browser session, the logo will display.
 *
 * @function
 * @returns {string} The logo image or an empty string.
 */
export const displayLogo = () => {
  if (isCoBrandedDomain()) {
    return localStorage.getItem('finalLogo') || '';
  } else {
    return logoCrosslink;
  }
};

/**
 * Returns boolean based on current domain
 *
 * @function
 * @returns {boolean} Returns false if not cobranded
 */
export const isCoBrandedDomain = () => {
  return (
    (ENVIRONMENT === 'production' && location.hostname.includes('myonlinetaxoffice')) ||
    (ENVIRONMENT !== 'production' && location.hostname.includes('cobrand'))
  );
};

/**
 * Handles parsing through the licenses array to get the highest and lowest season owned
 *
 * @param {Array} licenses licenses that the office owns
 * @returns {Object} highest and lowest season
 */
export const getHighLowSeasons = licenses => {
  const highestSeason = Math.max.apply(
    Math,
    licenses.map(license => {
      return license.season;
    }),
  );

  let lowestSeason = Math.min.apply(
    Math,
    licenses.map(license => {
      return license.season;
    }),
  );
  // only display two prior years from the lowest season owned
  lowestSeason = Math.max(2019, lowestSeason - 2);
  return { highestSeason, lowestSeason };
};

/**
 * Handle checking if login account code is CLODMO.
 *
 * @param {*} props
 * @returns boolean
 */
export const isDemoAccount = payload => {
  return payload?.account_code === 'CLODMO';
};

/**
 * Handles checking if tab should be disabled on condition
 * Used on disable = {shouldDisableSideBarItem(x,y)}
 * Currently used in returnProfile and sidebarform
 *
 * @param {boolean} conditional --i.e isDemoAccount
 * @param {*} tab -- i.e 'prin19'
 * @returns boolean
 */
export const shouldDisableSideBarItem = (conditional, tab) => {
  switch (tab) {
    case 'prin12':
    case 'prin18':
      return conditional;
    default:
      return false;
  }
};

// Gives Super and Tech support users Read only access to prevent saving info by the save interval that the customer sets.
export const isSuperOrTechUserReadOnly = payload => {
  if (
    ['QA', 'DEVELOPMENT', 'LOCAL'].includes(ENVIRONMENT.toUpperCase()) &&
    (payload?.hierarchy_type_id !== HIERARCHY_TYPE.TECH_SUPPORT ||
      payload?.hierarchy_type_id !== HIERARCHY_TYPE.TECH_SUPPORT_SBA) && // tech support cannot update a return - errors occur when saving and closing
    !payload?.is_tech_support_and_impersonating &&
    payload?.impersonating // allow superusers to edit only when impersonating
  ) {
    return false;
  }

  return (
    payload?.is_super_user ||
    payload?.is_tech_support_and_impersonating ||
    payload?.hierarchy_type_id === HIERARCHY_TYPE.TECH_SUPPORT ||
    payload?.hierarchy_type_id === HIERARCHY_TYPE.TECH_SUPPORT_SBA
  );
};

/**
 * Handles determining whether the current viewing user is `superuser` or `tech support`
 *
 * @param {object} payload jwt payload
 * @returns {boolean} isSuperOrTechSupport
 */
export const isSuperOrTechSupport = payload => {
  return (
    payload.is_super_user ||
    payload.is_tech_support_and_impersonating ||
    payload.hierarchy_type_id === HIERARCHY_TYPE.TECH_SUPPORT ||
    payload.hierarchy_type_id === HIERARCHY_TYPE.TECH_SUPPORT_SBA
  );
};

/**
 * Takes a JSON string value and trim null from it.
 *
 * @function
 * @param {String} aStr A JSON string value.
 * @returns A JSON string value without null.
 */
export const trimNull = aStr => {
  if (!aStr || aStr === '') return aStr;
  const nullByte = '\u0000';
  if (aStr.includes(nullByte)) {
    return aStr.replace(/\u0000/g, '');
  }
  return aStr;
};

export const copyToClipboard = copyText => {
  navigator.clipboard
    .writeText(copyText)
    .then(() => {
      alert(`Copied to clipboard: ${copyText}`);
    })
    .catch(error => {
      alert(`Error: ${error}`);
    });
};

/**
 * Takes an SSN and option masked boolean
 *
 * @function
 * @param {String} aStr A JSON string value.
 * @returns formatted SSN XXX-XXX-XXXX
 */
// formatSSNEIN correctly to display masked/unmasked
export function formatMaskSSNEIN(ssnein, isMasked = '0', isEIN = false) {
  if (isMasked === '1' && isEIN) {
    return 'XX-XXX' + ssnein?.slice(-4);
  } else if (isMasked === '1' && !isEIN) {
    return 'XXX-XX-' + ssnein?.slice(-4);
  }

  if (isEIN) {
    return String(ssnein)?.replace(/^(\d{2})(\d{7})$/, '$1-$2');
  } else {
    return String(ssnein)?.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
  }
}

// format ssn/ein and add the hyphens
export const formatSSNEIN = (ssnein, isBusiness) => {
  ssnein = removeHypen(ssnein);

  if (isBusiness) {
    if (ssnein.length > 2) {
      return `${ssnein.substring(0, 2)}-${ssnein.substring(2)}`;
    } else {
      return ssnein;
    }
  } else {
    if (ssnein.length > 3 && ssnein.length < 6) {
      return `${ssnein.substring(0, 3)}-${ssnein.substring(3)}`;
    } else if (ssnein.length >= 6) {
      return `${ssnein.substring(0, 3)}-${ssnein.substring(3, 5)}-${ssnein.substring(5)}`;
    } else {
      return ssnein;
    }
  }
};

/**
 * Checks if season and enviorment is acceptable for business
 *
 * @function
 * @param {int} season
 * @returns boolean
 * @function
 */
export const isVaidCorpSeason = season => {
  return season >= 2024;
};

/**
 * Checks if office has any business assets (Licence or Returns)
 *
 * @function
 * @param {Object} officeProfile
 * @returns boolean
 * @function
 */
export const hasCorpLicAssets = officeProfile => {
  return officeProfile?.hasBusinessLicense || officeProfile?.hasBusinessReturns;
};

/**
 * Checks if roles contains acceptable Business roles.
 *
 * @function
 * @param {Object} payload
 * @param {Object} currentView
 * @returns boolean
 * @function
 */
export const hasValidRoleForCorp = payload => {
  if (BUSINESS_VIEW_ROLES.includes(payload.roles?.[0]?.access_friendly_role_name)) {
    return true;
  }
  return payload?.hierarchy_type_id < ROLE_TYPE.EFIN; // MO, Reseller, and Techs have access to business regardless
};

/**
 * Combines season, asset, role checks
 *
 * @function
 * @param {Object} payload
 * @param {Object} officeProfile
 * @returns boolean
 * @function
 */
export const canAccessCorp = (payload, officeProfile, currentView) => {
  return (
    ((hasValidRoleForCorp(payload) && hasCorpLicAssets(officeProfile)) ||
      isTechOrAdminLoginFromRole(currentView)) &&
    isVaidCorpSeason(payload?.season)
  );
};

// Used for Friendly ID to System ID conversion
export const crockfordBase32ToDecimal = (base32String, isBusiness = false) => {
  const base32Alphabet = '0123456789ABCDEFGHJKMNPQRSTVWXYZ';
  const stringLength = base32String.length;
  let decimal = 0;

  for (let i = 0; i < stringLength; i++) {
    let value = 0;
    for (let j = 0; j < 32; j++) {
      if (base32String[i] === base32Alphabet[j]) {
        value = j;
        break;
      }
    }
    decimal = decimal * 32 + value;
  }

  return decimal - (isBusiness ? CORP_CONVERSION_PREFIX : TEN_FOURTY_CONVERSION_PREFIX);
};

// Used for System ID to Friednly ID conversion
export const decimalToCrockfordBase32 = (decimal, isBusiness = false) => {
  const base32Alphabet = '0123456789ABCDEFGHJKMNPQRSTVWXYZ';
  let base32StringBuilder = '';

  // get decimal by adding the system return id + the return type prefix
  decimal = decimal + (isBusiness ? CORP_CONVERSION_PREFIX : TEN_FOURTY_CONVERSION_PREFIX);

  // Handle 0
  if (decimal === 0) {
    return '0';
  }

  while (decimal > 0) {
    base32StringBuilder += base32Alphabet[decimal % 32];
    decimal = Math.floor(decimal / 32);
  }

  const base32String = base32StringBuilder;

  // Reverse the string
  const reverse = base32String.split('').reverse().join('');
  return reverse;
};

// route lsit can either be publicRoutes or privatesRoutes - returns true if a match is found
export const isOnPublicOrPrivateRoute = routeList => {
  const pathname = window.location.pathname;
  for (const route of routeList) {
    if (pathname === route.path) {
      return true;
    }
  }
  return false;
};

// Transalte a row occurance into a numeric row number
export const translateSpecialOccuranceRow = occNumber => {
  // if occurance number is an actual number, return it as a true int
  if (!isNaN(occNumber)) {
    return parseInt(occNumber);
  }

  // find the index of both occurance characters
  const tensIndex = OCCURANCE_CHART.indexOf(occNumber[0]);
  const onesIndex = OCCURANCE_CHART.indexOf(occNumber[1]);

  const trueOccurance = OCCURANCE_CHART.length * (tensIndex - 9) + onesIndex + 90;

  return parseInt(trueOccurance);
};

// Handles formatting firstName and lastName to `lastName, firstName`
export const buildFormattedName = (firstName, lastName, hasEmptyVal = false) => {
  let name = '';
  if (firstName && lastName) {
    name = `${lastName}, ${firstName}`;
  } else {
    // if one of them are missing, do not include the comma+space
    if (firstName || lastName) {
      name = firstName || lastName;

      // If niether are populated and we are choosing to have a default empty val
    } else if (hasEmptyVal) {
      name = '----';
    }
  }

  return name;
};
